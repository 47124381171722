.soc{
  display:flex;
  width:206px;
  justify-content:space-between;

  &__link{
    display:inline-block;
    vertical-align:top;
    width:30px;
    height:30px;
    background-size:auto;
    font-size:0;
    &:hover{ opacity:.7 }

  }
  &-big{
    display:flex;
    justify-content:space-between;
    flex-wrap:wrap;
    @include media($width_xs){
      margin:0 -1.5em -1em;
      justify-content:space-around;
      &__item{
        margin:0 1.5em 1em;
      }
    }
    &__link{
      color:rgba($clr_1, .5);
      font-size:0.813em;
      text-align:center;
      display:flex;
      flex-direction:column;
      line-height:1.2;
      align-items:center;
      width:6em;
      &:before{
        margin-bottom:.7em;
      }
    }
  }
}
