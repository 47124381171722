.func{
  &__list{
  }
  &__row{
    align-items:center;
    margin-top:5em;
    &:nth-child(odd){
      .func{
        &__reviews{ order:-2 }
        &__reviews-box{
          margin-right:auto;
        }
      }

    }
    &:nth-child(even){
      .func{
        &__text{ order:-1 }
        &__reviews-box{
          margin-left:auto;
        }
      }
    }
  }

  &__img{ max-width:18.375em;
  }
  .g-col{ justify-content:center; }
  &__reviews-box{
    margin-right:auto;
    will-change:transform;
    //animation:scale 5s linear infinite;

  }
  &__title{
    color:$clr_4;
    font-size:1.75em;
    font-weight:400;
    &_psevdo{ display:none }
  }
  @include media($width_xs){
    &__img{
      max-height:453px;
      display:block;
      margin:0 auto;
    }
    &__title{display:none;

      &_psevdo{
        display:block;
        font-size:1.125em;
        &:before{
          content:attr(data-text);
        }
      }
    }

    & &__row &__reviews{
      order:0;
      &-box{
        margin:0 auto;
      }
    }
    &__image{
      margin:1em 0 2em;
    }
    & &__row &__text{
      order:0;
      p{margin-bottom:1em}
    }
    &__row{
      margin-top:1em;
    }
  }
}
@keyframes scale{
  from, to{ -webkit-transform:scale(.95); transform:scale(.95); }
  50%{ -webkit-transform:scale(1); transform:scale(1); }
}