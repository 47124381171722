/* тревога, если первый child внутри списка не li и прочие похожие примеры */
ul > *:not(li),
ol > *:not(li),
dl > *:not(dt):not(dd){
  outline:2px solid red;
}
ul, ol{
  list-style:none;
  margin:0;
  padding:0;
  .g-list-wrap_mb_1 & li, .g-list-wrap_mb_1 &:not([class]) li{
    margin-bottom:.4em;
  }
  & + p{
    margin-top:2.5em;
  }
}
ul:not([class]), ol:not([class]){
  margin:0;
}
ol:not([class]){ list-style:decimal }
ul:not([class]){

  li{
  padding-left:30px;
  margin-bottom:1.1em;
  position:relative;
  &:not(.li-decor-no):before{
    display:block;
    content:"";
    width:10px;
    height:10px;
    border:2px solid #15b68e;
    border-radius:50%;
    box-sizing:border-box;
    position:absolute;
    top:.45em;
    left:0;
  }
  &.li-decor-no{cursor:default}
}
}
[data-dots-listener]{
  counter-reset:list;
  li{ cursor:pointer;
    counter-increment:list;

    @include media($width_xs, 1){
      &.active{
        color:$clr_4;
        &:after{
          position:absolute;
          content:counter(list);
          font-size:9px;
          display:block;
          width:15px;
          height:15px;
          background:$clr_4;
          color:$clr_2;
          border-radius:50%;
          box-sizing:border-box;
          top:.45em;
          left:-.2em;
          text-align:center;
          line-height:1.7;
        }
      }
    }

  }
}

