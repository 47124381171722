.index{
  &-main{
    color:$clr_2;
    @extend %bgi;
    background-image:url(../img/bg/bg_index_main.jpg);

    @media(max-width:$width_xs) and (orientation:landscape){
      background-image:none;
      position:relative;
      &:before{
        content:'';
        position:absolute;
        top:0;
        bottom:0;
        display:block;
        background-color:#121b22;
        left:-99em;
        right:-99em;
      }

    }

    background-color:$clr_5;
    .g-grid{
      padding-top:3em;
      padding-bottom:3.5em;
    }

    &__main{
      position:relative;
      z-index:1;
    }
    &__imgs{
      /*  position:absolute;
        right:0;
        left:300px;
        bottom: 0;*/
    }
    &__img{
      position:absolute;
      left:0;
      bottom:0;
      margin-bottom:-15px;
      &::before{
        position:relative;
      }
    }
    &__phone{
      &::before{content:url(../img/bg/bg_index_main_phone.png);}
      left:35%;
      bottom:-48px;
    }
    &__card{
      &::before{content:url(../img/bg/bg_index_main_card.png);}
      left:65%;
      bottom:-2px;
    }
    &__book{
      &::before{content:url(../img/bg/bg_index_main_book.png);}
      left:auto;
      right:-232px;
      bottom:-10px;
    }

    @include media($width_xs){
      &__img{display:none}
      &__img-xs{
        margin:0 -1em;
        text-align:center;
        &::after{
          padding-top:60%;
          margin:0 1em;
          display:block;
          content:'';
          background:url(../img/bg/bg_index_main_imgs_xs.png) no-repeat center/contain;
        }
      }
    }
  }

  &-products{
    &__item:nth-child(odd){
      text-align:right;
    }
    &__item:nth-child(odd) &__link{ margin-left:auto }
    &__item:nth-child(odd) &__img{ left:auto; right:100%; margin-right:-9em; margin-left:auto }
    &__list{
      margin-top:3em;
    }
    &__link{
      display:block;
      width:100%;
      border:3px solid $clr_4;
      border-radius:$br_1;
      padding:3em;
      position:relative;
      margin-bottom:2em;
      max-width:383px;
    }
    &__link:hover &__img{
      /* filter:grayscale(100%);*/
      transform:scale(.98) translateY(-47%);
    }
    &__img{
      position:absolute;
      left:100%;
      margin-left:-7em;
      top:50%;
      transform:translateY(-50%);
      transition:$trans;
    }
    &__more{
      color:$clr_4;
    }
    &__name{
      display:block;
      color:$clr_4;
      font-size:2.25em;
      font-weight:400;
      line-height:1;
      margin-bottom:.5em;
    }
    &__text{
      min-height:4.5em;
    }

    @include media($width_xs){
      & &__item{
        text-align:center;
      }
      &__link{
        margin-right:auto;
        margin-left:auto;
      }
      & &__item &__img{
        position:static;
        margin:0 0 1em;
        transform:translateY(0);
        max-height:200px;
      }
      &__text br{display:none}
      &__text{ min-height:auto;}
      &__link{ padding:2em;}
    }
  }
}