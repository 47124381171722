.text-box-img{
  &__img-wrap{
    position:relative;
    overflow:hidden;
    background-color:$clr_6;
    border-radius:$br_2;
    margin-bottom:-6em;
    padding-bottom:3.5em;
    @extend %bsh_2;
    &:before{
      @extend %abs;
      @extend %bgi;
      margin:-1.8em;
      animation:scale 20s linear infinite;
    }
  }
  &__box{

    &_man .text-box-img__img-wrap:before{
      background-image:url(../img/text_box/text_box_img_man.jpg);
    }
    &_girl .text-box-img__img-wrap:before{
      background-image:url(../img/text_box/text_box_img_girl.jpg);
    }
    &_girl{
      margin-bottom:9em;
    }
    &_girl{
      ul li{ margin-bottom:2em }
    }
  }
  &__text{
    padding:5em 6% 4em 6%;
    max-width:570px;
    position:relative;
  }
  &__btn{
    padding-left:6%;
    margin-top:-3em;
    top:2.8em;
    position:relative;
  }

  @include media($width_xs){
    &__text{
      padding:2em 2em 0;
    }
    &__btn{
      padding-left:0em;
      margin-top:-3em;
      top:2.8em;
      position:relative;
    }
    &__box{margin-bottom:6em;
      &_girl .text-box-img__img-wrap:before{
        background-position:60%;
      }
    }

  }
}