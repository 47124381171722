.product-target{
  &__list{
    display:flex;
    justify-content:space-between;
    flex-flow:row wrap;
    align-items:center;
    margin:2.6em auto 0;
    text-align:center;
  }
  &__item{
    width:8em;
    &:before{
      content:'';
      background:no-repeat center;
      display:block;
      width:8.25em;
      height:8.25em;
      background-color:white;
      @extend %bsh_2;
      border-radius:50%;
      margin:0 auto 1em;
    }

  }
  &__item_1:before{ background-image:svg-load('product-target/w_icon_1.svg'); }
  &__item_2:before{ background-image:svg-load('product-target/w_icon_2.svg'); }
  &__item_3:before{ background-image:svg-load('product-target/w_icon_3.svg'); }
  &__item_4:before{ background-image:svg-load('product-target/w_icon_4.svg'); }
  &__item_5:before{ background-image:svg-load('product-target/w_icon_5.svg'); }

  @include media($width_xs){
    &__list{
      justify-content:center;
      margin:0 -1em -1em;
    }
    &__item{
      margin:0 1em 1em;
      &:before{font-size:.8em}
    }
  }

}
