.reviews-item{
  background:$clr_6;
  border-radius:$br_2;
  padding:5em 3em 3em;
  display:block;
  position:relative;
  //&:hover &__avatar{transform:translateY(10%)}
  &__avatar{
    border-radius:50%;
    @extend %bgi;
    height:100px;
    width:100px;
    background:$clr_2;
    position:absolute;
    left:3em;
    top:-3em;
    transition:$trans;
  }
  &__name{
    font-size:0.875em;
  }
  &__text{
    margin-bottom:3em;
  }

  &_box{
    border:5px solid $clr_4;
    padding:2em 1em 1em;
    background-color:transparent;
    max-width:300px;
    position:relative;

    &:before{
      position:absolute;
      content:'“';
      color:#eceded;
      left:21px;
      top:41%;
      font-size:10em;
      z-index:-1;
      font-weight:900;
    }

    .reviews-item{
      &__top{
        display:flex;
        padding:0 1em 1.5em;
        padding-right:0;
      }
      &__avatar{
        position:static;
        flex:1 0 auto;
        margin-right:1em;
        @extend %bsh;
      }
    }
  }

  @include media($width_xs){
    padding:5em 1em 2em;
    &_box{
      padding:1em;
      display:none;
    }
  }
}

