//FONT
$font__family_main:'Fira Sans', Helvetica, Arial, sans-serif;
$font__family_book:'Fira Sans Book', Arial, sans-serif;
$font-size_main:16px;
$margin-padding:10px;
$width_xs:767px;
$width_md:1024px;
$width_lg:1169px;

$m_em:() !global;
$m_rem:() !global;
@for $i from 1 through 10{
  $val:$margin-padding  / $font-size_main * $i;
  $m_em:append($m_em, $val + em);
  $m_rem:append($m_rem, $val + rem);
}
@function em($step) {
  @return nth($m_em, $step)
}

@function em-parent($fs,$m_em) {
  $m_px:($m_em / 1em) * ($font-size_main / 1px);
  $fsm_px:($font-size_main / 1px) * ($fs / 1em);
  @return $m_px / $fsm_px+em;
}

$fs_05:.5em;
$fs_1:1em;

//COLORS
$clr_1:#121212;
$clr_2:white;
$clr_3:#fbd748;
$clr_4:#15b68e;
$clr_5:#293744;
$clr_6:#f0f9fb;
$clr_7:#3d59ff;


%bggr_1{
  background-image:linear-gradient(to top, #09a16e 0%, #87c443 49%, #95eb35 100%);
}


$trans:.3s ease-in-out;
$trans_border:border .3s ease-in-out;


%bgir{ background:no-repeat center/cover }
%bgin{ background:no-repeat center/contain }
$br_1:10px;
$br_2:20px;

//GRID
$grid-width:1146px;
$grid-gutter:30px;
$grid-columns:12;

//FORM

//MIXIN
@import "includes/mixins";


