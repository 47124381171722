@import "includes/vars.scss";
@import "plugins/plugins.scss";
@import "includes/common.scss";
@import "includes/grid.scss";
@import "includes/icons.scss";
@import "includes/typo.scss";
@import "includes/print.scss";
/*! Blocks */
@import "blocks/nav-main-toggle.scss";
@import "blocks/page.scss";
@import "blocks/header.scss";
@import "blocks/index.scss";
@import "blocks/soc.scss";
@import "blocks/nav_main.scss";
@import "blocks/footer.scss";
@import "blocks/slider.scss";
@import "blocks/form.scss";
@import "blocks/list.scss";
@import "blocks/link.scss";
@import "blocks/timer.scss";
@import "blocks/decor-circle.scss";
@import "blocks/product_target.scss";
@import "blocks/reviews-item.scss";
@import "blocks/statistic.scss";
@import "blocks/video-list.scss";
@import "blocks/text-box-img.scss";
@import "blocks/subs.scss";
@import "blocks/box-img-circle.scss";
@import "blocks/map-box.scss";
@import "blocks/box-main.scss";
@import "blocks/section_text.scss";
@import "blocks/rates.scss";
@import "blocks/box-store.scss";
@import "blocks/func.scss";
@import "blocks/advantages.scss";
@import "blocks/works.scss";
@import "blocks/faq.scss";
@import "blocks/request-decor.scss";
@import "blocks/box-present.scss";
@import "blocks/popup.scss";
@import "blocks/404.scss";
/*! END Blocks */
@import "includes/global.scss";