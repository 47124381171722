.statistic{
  &__item{
    position:relative;
    z-index:2;
  }
  &__box{
    @extend %bgi;
    border-radius:$br_2;
    padding:5em 0;
    background-image:url(../img/bg/bg_statistic.png);
    background-position:top right;
    background-color:$clr_5;
    color:$clr_2;
  }
  &__circle{
    @extend %decor-circle;
    @extend %decor-circle_anim;
    // Style for "Ellipse 8"
    width:16.563em;
    height:16.5em;
    margin:1.5em 0 0;
    display:inline-flex;

  }
  &__title{
    font-size:3.375em;
    width:100%;
    margin-bottom:.2em;
    line-height:1;
  }
  &__text{
    &:before{
      margin:3.5em -1em 2em;
      content:'';
      display:block;
      height:2px;
      background-color:#38434d;
    }
  }
  &__footer{
    background:$clr_3;
    font-size:1.25em;
    color:$clr_5;
    border-radius:$br_2;
    font-weight:400;
    padding:1.4em;
    text-align:center;
    margin-top:-1.5em;
    @extend %bsh;
  }

  &_phone{
    position:relative;
    &:before{
      content:url(../img/page_mobilapp/bg_statistic-phone-decor.png);
      position:absolute;
      bottom:100%;
      left:50%;
      transform:translateX(-50%);
      z-index:-1;
      margin-bottom:-11em;
    }
    .statistic{
      &__img-wrap{
        position:absolute;
        top:auto;
        bottom:0;
        margin-bottom:-1em;
        transform:translate(0, 0);
        right:0;
        margin-right:-2em;
        width:36%;
      }
      &__img-text{
        padding-left:5%;
      }
    }

  }

  @include media($width_xs){
    &_phone{display:none;}
    &__img-wrap{display:none}
    &__box, &_phone &__img-text{
      padding:2em 2em;
    }
    &__box{padding-bottom:2em}
    &__text{display:none}
    &__text:before{
      margin:2em -1em;
    }
    &__circle{
      width:10em;
      height:10em;
      line-height:1;
    }
    &__footer{
      padding:1em;
      margin-top:-0.5em;
    }

  }

}