.video-list{
  &__list{
    margin-top:2.5em;
    margin-left:-1em;
    margin-right:-1em;
    .slick-slide{
      padding:0 1em;
    }
  }
  &__item{
    display:block;
  }
  &__item:hover &__play{transform:scale(1.1) translate(-50%, -50%)}
  &__image{
    @extend %bgi;
    border-radius:$br_2;
    height:12.75rem;
    position:relative;
    margin-bottom:1em;
    a:not([data-colorbox]) &{
      background-size:auto;
      min-width:315px;
    }
  }
  &__name{
    font-size:0.938rem;
    font-weight:700;
    text-transform:uppercase;
    margin:.3em 0 .4em;
  }
  &__descr{
    color:$clr_4;
  }
  &__play{
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    transition:$trans;
  }

  @include media($width_xs){
    &__list{
      margin-left:0;
      margin-right:0;
      .slick-slide{
        padding:0 0;
      }
    }
  }
}