.slick-arrow, %btn-arrow{ background-color:$clr_3; border-radius:50%; min-width:60px;width:60px; height:60px; line-height:55px; box-shadow:0 12px 16px -8px rgba(240, 31, 85, 0.39); @extend %btn-hover }
.slick-arrow svg{ fill:#b59c38; height:18px; }
.slick-dots li{
  width:10px;
  height:10px;
  border:2px solid transparent;
  background:#f2f4f6;
  &.slick-active,
  &:hover{
    background:$clr_3;
  }
}
.slick-prev{
  left:-2.5em;
}
.slick-next{
  right:-2.5em;
}
.slick-dots{
  position:static;
  margin:2em 0;
}

.slider-clients{

  max-width:500px;
  margin:0 auto;
  padding:3em 0;
  background:$clr_2;
  box-shadow:0 0.313em 1.125em -.5em rgba(101, 97, 98, 0.77);
  border-radius:$br_2;

  &__wrap{
    user-select:none;
  }

  &__item{
    padding:1.7em 20%;
  }
  &__img{
    @extend %bgi;
    background-size:contain;
    height:60px;

  }
  .slick-dots{
    margin:0;
  }

  @include media($width_md){
    margin:0 5em;
  }

  @include media($width_xs){
    margin:0;
    margin-top:2em;
    padding-top:2em;
    &__wrap{width:100%}
    &__item{
      padding:1em;
    }
  }
}

.slider-reviews{
  margin:3em -15px 0;

  &__item{
    padding:3em 15px 0;
    height:100%;
    .reviews-item__text{
      height:210px;
      overflow-x:auto;

      @include media($width_xs){
        height:auto;
        min-height:210px;
        margin-bottom:0;
      }
    }
  }
  &__slider{}
}

.slider-screen-img{
  position:relative;
  &:before, .slider-screen{
    position:absolute;
    padding-top:100%;
    width:140%;
    @extend %pos_img_content;
    @extend %bgi;
    background-image:url(../img/bg/bg_mac_inner_empty.png);
    left:50%;
    transform:translate(-50%, -50%);
    content:'';
    background-size:contain;
  }

  .slider-screen{
    z-index:1;
    background:none;
    padding-top:56.8%;
    transform:translate(-50%, -55.5%);
    width:90.8%;
    .slick-list{@extend %abs;height:100% !important;}
    .slick-track{height:100% !important;}
    .slick-slide{
      position:relative;
    }
    &__img{
      @extend %bgi;
      @extend %abs;
    }

    .slick-next{
      margin-right:-1.2em;
    }
    .slick-prev{
      margin-left:-1.2em;
    }

    @include media($width_xs){
    }
  }

  &_evator{
    &:before{
      padding-top:86%;
      width:48%;
      background-image:url(../img/page_erp/bg_evator.png);
      @include media($width_xs){
        padding-top:182%;
        width:89%;
      }
    }
    .slider-screen{
      padding-top:58%;
      transform:translate(-50%, -41%);
      width:37%;
      .slick-arrow{
        margin-top:-2.5em;
      }
      .slick-next{
        margin-right:-3.5em;
      }
      .slick-prev{
        margin-left:-3.5em;
      }

    }
  }
  &_includes{
    &:before{
      padding-top:121%;
      width:88%;
      background-image:url(../img/page_mobilapp/slider_includes/mobilapp_includes.png);
    }
    .slider-screen{
      padding-top:96%;
      transform:translate(-50%, -50%);
      width:54%;
    }
  }

  @include media($width_xs){
    &_evator .slider-screen.slick-slider{
      margin:8em auto;
      transform:translate(0, 34px);
      padding-top:113%;
      width:74%;
    }
    .slider-screen{
      position:static;
      width:91%;
      transform:translate(5%, -5%);
      margin:5em 0;
    }
  }
}
.slider-examples{
  &__slider{
    margin-bottom:-3.5em;
  }
  &__btns{display:inline-flex; margin:1em 0 6em;}
  &__btn{
    margin:0 1.1em;
    display:inline-block;
    transition:$trans;
    line-height:1.1;
    cursor:pointer;
    &.active{
      font-weight:inherit !important;
    }
    &:after{ display:none }
    &:before{
      display:block;
      background:no-repeat center/cover;
      background-color:$clr_6;
      background-size:auto;
      border-radius:50%;
      width:130px;
      height:130px;
      content:'';
      margin-bottom:1.7em;
      transition:$trans;
      border:2px solid transparent;
    }

    &:hover:before{
      border-color:rgba($clr_4, .5);
    }
    &.active:before{
      border-color:$clr_4;
    }
    &.active{
      color:$clr_4;
    }
    &_1:before{
      background-image:svg-load('icon_example_1.svg', height=48);
    }
    &_2:before{
      background-image:svg-load('icon_example_2.svg');
      background-size:63%;
    }
  }

  &__img{ max-width:500px }
  &__item{
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
    max-width:886px;
    margin:0 auto;
  }

}
.slider-func{

  .slick-slide > div, .slick-slide > div > div, .slick-slide > div > div > div{height:99%}
  .slick-arrow{
    top:37%;
  }
  &__slider{
    max-width:330px*3;
    margin:2.5em auto 0;
  }

  &__circle{
    @extend %decor-circle;
    width:9.625em;
    height:9.625em;
    position:relative;
    margin-bottom:2em;
  }

  &__icon{
    @extend %abs;
    background:no-repeat center;
  }
  &__icon_1{ background-image:svg-load('icon_erp_func_1.svg') }
  &__icon_2{ background-image:svg-load('icon_erp_func_2.svg') }
  &__icon_3{ background-image:svg-load('icon_erp_func_3.svg') }
  &__icon_4{ background-image:svg-load('icon_erp_func_4.svg') }
  &__icon_5{ background-image:svg-load('icon_erp_func_5.svg') }
  &__icon_6{ background-image:svg-load('icon_erp_func_6.svg') }






  &__item{
    @extend %bsh_2;
    border-radius:$br_1;
    padding:2.5em 2em 1em;
    margin:0 1em 1em;
    background-color:#f4fafa;
    height:100%;
    /*min-height:528px;*/
  }
  &__list-wrap{
    margin-right:-1.5em;
  }
  &__name{
    margin:1.7em 0 1em;
    font-size:1.25em;
  }

  @include media($width_xs){
    &__item{
      padding:1.5em 1em 1em;
      margin:0 0em 1em;
    }
    &__list-wrap{
      margin-right:0;
    }
  }
}
.slider-mobil{
  &__slider{
  }
  &__grid{
    position:relative;
    max-width:1400px;
    margin:0 auto;
  }
  &__slider-wrap{
    position:relative;
    margin-top:4em;
    padding-bottom:17em;

    &:before{
      content:'';
      display:block;
      width:610px;
      height:880px;
      background:url('../img/page_mobilapp/slider_mobil/hand.png') 0 0 no-repeat;
      background-size:contain;
      position:absolute;
      top:68px;;
      left:50%;
      margin-left:-205px;
      margin-top:-8px;
      z-index:1;
      pointer-events:none;
    }
  }
  &__item{
    width:242px;
    height:532px;
    position:relative;

    &:before{
      background-size:auto;
      background-position:center top;
      background-repeat:no-repeat;
      height:55px;
      position:absolute;
      left:0; top:0; right:0;
      content:'';
    }
    &:after{
      content:'';
      position:absolute;
      bottom:0;
      left:0;
      top:127px;
      right:0;
      @extend %bgi;
    }

    &_1:before{ background-image:url('../img/page_mobilapp/slider_mobil/l1.png'); }
    &_1:after{ background-image:url('../img/page_mobilapp/slider_mobil/lt1.png'); }
    &_2:before{ background-image:url('../img/page_mobilapp/slider_mobil/l2.png'); }
    &_2:after{ background-image:url('../img/page_mobilapp/slider_mobil/lt2.png'); }
    &_3:before{ background-image:url('../img/page_mobilapp/slider_mobil/l3.png'); }
    &_3:after{ background-image:url('../img/page_mobilapp/slider_mobil/lt3.png'); }
    &_4:before{ background-image:url('../img/page_mobilapp/slider_mobil/l4.png'); }
    &_4:after{ background-image:url('../img/page_mobilapp/slider_mobil/lt4.png'); }
    &_5:before{ background-image:url('../img/page_mobilapp/slider_mobil/l5.png'); }
    &_5:after{ background-image:url('../img/page_mobilapp/slider_mobil/lt5.png'); }
    &_6:before{ background-image:url('../img/page_mobilapp/slider_mobil/l6.png'); }
    &_6:after{ background-image:url('../img/page_mobilapp/slider_mobil/lt6.png'); }
    &_7:before{ background-image:url('../img/page_mobilapp/slider_mobil/l7.png'); }
    &_7:after{ background-image:url('../img/page_mobilapp/slider_mobil/lt7.png'); }
    &_8:before{ background-image:url('../img/page_mobilapp/slider_mobil/l8.png'); }
    &_8:after{ background-image:url('../img/page_mobilapp/slider_mobil/lt8.png'); }

  }

  &__download{
    max-width:385px;
    border:4px solid $clr_4;
    border-radius:$br_2;
    padding:2.5em 2em 1em;
    position:absolute;
    left:6em;
    bottom:-21em;
    background:$clr_2;
    &-text{
      font-size:0.875em;
      margin-bottom:2em;
    }
    &-btns{ display:flex; max-width:150px; margin:0 auto; justify-content:space-between; transform:translate(-1em, 4px); }
    &-btn:hover{ opacity:.8 }
    &-btn_1:before{ content:url('../img/page_mobilapp/slider_mobil/iOS.png'); }
    &-btn_2:before{ content:url('../img/page_mobilapp/slider_mobil/Android.png'); }
  }
  @include media(1600px){
    padding:0 1em;

    &__slider-wrap:before{
      margin-left:-189px;
    }

    &__grid{
      max-width:820px;
    }
    &__item{
      margin:0 auto;
    }

    &__download{
      left:0;
      margin:1em;
      margin-bottom:-1em;
      padding:1em;
      z-index:1;
    }
  }


}
.slider-awards{
}


