.footer{
  padding:0 0 1em;

  &__list{
    margin-top:2em;
    margin-bottom:2em;
  }
  &__title{
    margin-bottom:1.7em;
  }
  @include media($width_xs){

    .header__main{display:none}
    .g-col{margin-top:2em}
    .footer__list{
      margin-top:0;
      margin-bottom:0;
    }
    .footer__hr{}
    .footer__cop{display:block}
  }
}