.p404{
  text-align:center;
  &__title{
    font-size:4.5em;
  }
  &__text{
    font-size:1.500em;
    font-weight:300;
    margin-bottom:2em;
  }
}