.faq{
  &__list{
    margin-top:2em;
  }
  &__item{
    @extend %bsh_2;
    background-color:$clr_6;
    border-radius:$br_1;
    cursor:pointer;
    padding:2.5em;

    & + &{
      margin-top:1em;
    }

  }
  &__item.open &__name:after{ transform:translate(0) rotate(-90deg);box-shadow:none }
  &__name{
    color:$clr_4;
    font-size:1.25em;
    margin-bottom:0;
    position:relative;
    padding-right:5em;
    &:after{
      content:'';
      left:100%;
      margin:-1.5em -3.5em;
      @extend %pos_img_content;
      @extend .btn-arrow;
      box-shadow:0.5em 0 1em -.5em rgba(101, 97, 98, 0.45);
      background-color:$clr_2;
      background-image:svg-load('icon_arrow.svg', fill="#b7b7b7");
      transform:translate(0) rotate(90deg);
    }
    &:hover:after{
      transform:translate(0) rotate(90deg);
      box-shadow:none
    }
  }
  &__text{
    display:none;
    padding:2em 0 0;
    margin-top:2em;
    border-top:1px solid #e2e2e2;
  }
}