@include font-face('Fira Sans Book', 'FiraSans-Book', 'normal', 400);
.page{
  background:white; font:300 16px/1.5 "Roboto", Arial, Helvetica, sans-serif;
  color:$clr_1;
  &__ow{
    overflow-x:hidden;
  }
  &__wrap{
    display:flex;
    min-height:100vh;
    flex-direction:column;
    height:100%;
    width:100%;

    /*  overflow-x:hidden;
     !* overflow-y:scroll;*!*/
    z-index:1;
    position:relative;
    padding-top:118px;
    /*overflow-x: hidden;*/
    /* overflow-y: scroll;*/
  }

  &__main{
    flex:1;
  }
  &__nav-main{
    margin-top:.3em;
  }
  &__404{
    .btn{}
  }
  &__nav-main-toggle__link {
    transition:$trans_border;
  }
  @include media($width_xs){

    &__nav-main-toggle{
      position:fixed;
      background:$clr_2;
      left:-1em;
      right:-1em;
      top:0;
      height:65px;
      z-index:332;
      box-shadow:0 0.5em 1em -.5em rgba(101, 97, 98, 0.45);
      .nav-main-toggle{
        margin:20px 35px;
      }
      &__link{
        background:$clr_2 url(../img/logo_xs_2.svg) no-repeat center/contain;
        width:195px;
        height:55px;
        display:block;
        position:absolute;
        left:30px;
        top:5px;
      }
    }
    &__wrap{padding-top:65px}
    @media(max-width:$width_xs) and (orientation:landscape){
      &__wrap{max-width:450px;margin:0 auto}
    }

  }
}



