/* colorbox */
#colorbox, #cboxOverlay, #cboxWrapper{ position:absolute; top:0; left:0; z-index:9999; overflow:hidden; }
#cboxWrapper{ max-width:none; }
#cboxOverlay{ position:fixed; width:100%; height:100%; }
#cboxMiddleLeft, #cboxBottomLeft{ clear:left; }
#cboxContent{ position:relative; }
#cboxLoadedContent{ overflow:auto; -webkit-overflow-scrolling:touch; }
#cboxTitle{ margin:0; }
#cboxLoadingOverlay, #cboxLoadingGraphic{ position:absolute; top:0; left:0; width:100%; height:100%; }
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{ cursor:pointer; }
.cboxPhoto{ float:left; margin:auto; border:0; display:block; max-width:none; -ms-interpolation-mode:bicubic; }
.cboxIframe{ width:100%; height:100%; display:block; border:0; padding:0; margin:0; }
#colorbox, #cboxContent, #cboxLoadedContent{ box-sizing:content-box; -moz-box-sizing:content-box; -webkit-box-sizing:content-box; }
#cboxOverlay{ background:#000; opacity:.9; filter:alpha(opacity=90); }
#colorbox{ outline:0; }
#cboxContent{ margin:40px; overflow:visible; }
.cboxIframe{ background:#fff; }
#cboxError{ padding:50px; border:1px solid #ccc; }
#cboxLoadedContent{ padding:2em; background:#fff; }
#cboxLoadingOverlay{ background:#fff; }
#cboxTitle{ position:absolute; top:0; left:0; }
#cboxCurrent{ position:absolute; top:0; right:0; }
#colorbox #cboxClose{ position:absolute; top:50%; margin:-20px 0 0; padding:0; background:none; border:0; overflow:visible; font-size:32px; line-height:40px; text-align:center; color:#fff; border-radius:0; box-shadow:none;
    width:59px;
    height:59px;
    background:no-repeat center/cover;
    background-image:url(/img/icons/icon-close.png); }
#colorbox #cboxPrevious{ transform:translateX(-110%); }
#colorbox #cboxNext{ }
#colorbox #cboxClose{ top:-12px; right:-30px; }
#cboxCurrent, #cboxTitle{ margin:.5em 2em; }
#colorbox #cboxNext, #colorbox #cboxPrevious{
    background:none;
    padding:0;
    margin:0;
    border:0;
    position:absolute;
    bottom:50px;
    left:52%;
}
/* colorbox END */