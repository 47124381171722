
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{ margin:0 0 1em; line-height:normal; font-weight:300 }
h1, .h1{ font-size:2.375em;
  &_lg{
    font-size:3.375em;
    margin-bottom:.5em;
  }
}
h2, .h2{ font-size:1.75em; font-weight:400; color:$clr_4;
  &_lg{
    font-size:2.25em;
    font-weight:300;
  }
  &:not(.h2_no_line){ margin-left:-.08em; }
  &:not(.h2_no_line):before{ margin-left:.09em; content:''; display:block; height:2px; width:60px; background:$clr_4; margin-bottom:.75em }
  &.g-ta_c_xs:before{ margin-left:auto; margin-right:auto }
}
h3, .h3{ font-size:1.125em; font-weight:500; }
h4, .h4{ font-size:1em; font-weight:400; }
h5, .h5{ font-size:1em; }
h6, .h6{ font-size:0.75em; }
p{ margin:0 0 nth($m_em, 2);
  &:last-child{ margin-bottom:0 }
}
small, .small{font-size:.875em}
@include media($width_xs){
  h1, .h1{ font-size:2em;
    &_lg{
      font-size:2em;
      margin-bottom:.5em;
    }
  }
  h2, .h2{ font-size:1.5em;;
    &_lg{
      font-size:1.75em;
    }

  }

}


