.popup{
  &-wrap{
    /* iframe{width:500px;height:500px}*/
  }
  width:350px;
  box-sizing:border-box;
  .h1{
    font-size:1.75em;
  }
  &-wrap_success{
    .popup{width:285px;}

    .h1{ font-weight:500;margin:.5em 0 1.5em;}

    #cboxLoadedContent{

    }

    .soc-big{
      font-size:1.2em;
      margin:1.4em 0 0;
      max-width:275px;
    }
    .soc-big__link{
      color:white;

      &:before{

      }
      &.icon-soc-big_inst:before{ line-height:6em;}
    }
  }

  &-wrap_sale{
    #cboxLoadedContent{
      background-color:$clr_2;
      color:$clr_1;
    }
    .popup{
      width:500px;
    }
  }

  @include media($width_xs){
    &-wrap_sale &{width:100%;min-width:100%}
    width:100%;
    min-width:100%;

  }

}
#colorbox #cboxClose{
  @extend %btn-arrow;
  color:#a39352;
  width:50px;
  height:50px;
  line-height:50px;
  top:-20px;
  right:-60px;
  min-width:auto;

  @include media($width_xs){
    right:0;
  }
}
#cboxContent{
  margin:4em;
  @include media($width_xs){
    margin:3em .5em;
  }
}
#cboxLoadedContent{
  background-color:$clr_5;
  color:$clr_2;
  border-radius:$br_2;

  @include media($width_xs){
    padding:1.3em 1.5em 1.5em;

  }
}
.popup-header-decor{
  color:$clr_2;
  @extend %bggr_1;
  border-radius:$br_2;
  margin:-2em -2em 2em;
  padding:2em;

  @include media($width_xs){
    border-radius:20px 20px 0 0;
    margin:-1.3em -1.5em 1.5em;
    padding:1.3em 1.5em 1.5em;
  }

}
/*#colorbox, #cboxOverlay{position:fixed}*/

