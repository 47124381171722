.box-img-circle{
  &__img-wrap{
    position:relative;
  }
  &__img{ position:relative; left:-6%; top:-5% }
  &__circle{
    background-color:$clr_4;
    background-color:#23ad7e;
    border-radius:50%;
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    padding-top:90%;
    width:90%;
  }
  &__icon{
    position:absolute;
    left:0;
    top:0;
    z-index:1;
    &:before{position:relative}
    &_1_1{ @extend %icon1;
      &::before{content:svg-load('icon_other_1_1.svg')}
    }
    &_1_2{ @extend %icon2;
      &::before{content:svg-load('icon_other_1_2.svg')}
    }
    &_1_3{ @extend %icon3;
      &::before{content:svg-load('icon_other_1_3.svg')}
    }

    &_2_1{ @extend %icon1;
      &::before{content:svg-load('icon_other_2_1.svg')}
    }
    &_2_2{ @extend %icon2;
      &::before{content:svg-load('icon_other_2_2.svg')}
    }
    &_2_3{ @extend %icon3;
      &::before{content:svg-load('icon_other_2_3.svg')}
    }

    &_3_1{ left:23%; top:6%;
      &::before{content:svg-load('icon_other_3_1.svg')}
    }
    &_3_2{ left:59%; top:89%;
      &::before{content:svg-load('icon_other_3_2.svg')}

      @include media($width_xs){
        top:57%;
      }
    }
    &_3_3{ left:15%; top:51%;
      &::before{content:url('../img/icons/icon_other_3_3.png')}
    ;
      & + .box-img-circle__imge{ left:6%; }

      @include media($width_xs){
        transform:scale(.5);
        top:31%;
        left:12%
      }
    }

  }
}
%icon1{
  left:1%;
  top:24%;
}
%icon2{
  left:45%;
  top:76%;

  @include media($width_xs){
    top:55%;
  }
}
%icon3{
  left:59%;
  top:-11%;
}