.timer{
  background:$clr_3;
  color:#a69646;
  font-size:0.875em;
  font-weight:400;
  text-align:center;
  border-radius:$br_1;
  padding:.4em 1em;
  margin:0em 0 1em;
  &__title{
  }
  &__list{
    display:flex;
    justify-content:space-between;
  }
  &__numb{
    background:$clr_2;
    color:$clr_1;
    font-size:1.5em;
    font-weight:700;
    display:block;
    border-radius:$br_1;
    width:54px;
    height:45px;
    line-height:45px;
  }
}