.box-store{
  background-color:$clr_5;
  color:$clr_2;
  border-radius:$br_2;
  padding:2em 4em .5em;
  max-width:854px;
  margin-left:auto;
  margin-right:auto;
  &__title{
    line-height:1.5;
    margin-top:.7em;
  }
  &__btn{
    position:relative;
    display:inline-block;
    &::before{
      position:relative;
    }
  }
  &__btn:hover{
    transform:scale(.9);
  }
  &__btn_app::before{ content:url(../img/btn-app-store.png); }
  &__btn_google::before{ content:url(../img/btn-google-play.png); }

  @include media($width_xs){
    padding:1em 1em .5em;
  }
  &__title{
    text-align:center;
  }
  &__btn{
    background:no-repeat center/contain;
    height:75px;
    display:block;
    &:before{content:'';}
  }
  &__btn_app{
    background-image:url(../img/btn-app-store.png);
  }
  &__btn_google{
    background-image:url(../img/btn-google-play.png);
  }
}