
.decor-circle, %decor-circle{
  background-image:linear-gradient(to top, #09a16e 0%, #87c443 49%, #95eb35 100%);
  border-radius:50%;

  color:$clr_2;
  text-align:center;
  display:flex;
  align-items:center;
  flex-direction:column;
  justify-content:center;
  margin:0 auto;
  padding:1em;

  &_anim{
    @extend %decor-circle_anim
  }

  &_text_big{
    font-size:1.25em;
    font-weight:400;
    width:400px;
    height:400px;

    @include media($width_xs){
      & br{display:none}

      width:300px;
      height:290px;
    }
  }
  &-go{
    position:absolute;
    bottom:-.3em;
    right:0em;
    border-radius:50%;
    background:$clr_3;
    color:$clr_2;
    text-align:center;
    display:flex;
    align-items:center;
    justify-content:center;
    margin:0 auto;
    padding:1em;
    width:2.8em;
    height:2.8em;
    @extend %bsh;
    text-shadow:0 0.125em 0.375em rgba(255, 115, 64, 0.2);
    font-size:3rem;
    font-weight:700;
    text-transform:uppercase;
    @extend %btn-hover;

    &:before{
      @extend %oreol;
    }

    @include media($width_xs){
      font-size:1.5em;
    }
  }

  @include media($width_xs){
    animation:none;
    background-color:#09a16e;
    background-image:none;
  }
}
%decor-circle_anim{
  position:relative;
  background:none;
  &:after{
    background-image:linear-gradient(to top, #09a16e 0%, #87c443 49%, #95eb35 100%);
    @extend %abs;
    z-index:-1;
    border-radius:50%;
    animation:form-line 2s linear infinite alternate;

    @include media($width_xs){
      animation:none;
      /*    background-color:#09a16e;
          background-image:none;*/
    }
  }

}
