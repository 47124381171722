/* slick */
.slick-slider{ display:block; position:relative; -webkit-box-sizing:border-box; -moz-box-sizing:border-box; box-sizing:border-box; -webkit-touch-callout:none; -khtml-user-select:none; -ms-touch-action:pan-y; touch-action:pan-y; -webkit-tap-highlight-color:transparent; }
.slick-list{ display:block; position:relative; overflow:hidden; margin:0; padding:0; }
.slick-list:focus{ outline:none; }
.slick-list.dragging{ cursor:pointer; }
.slick-slider .slick-track, .slick-slider .slick-list{ -webkit-transform:translate3d(0, 0, 0); -moz-transform:translate3d(0, 0, 0); -ms-transform:translate3d(0, 0, 0); -o-transform:translate3d(0, 0, 0); transform:translate3d(0, 0, 0); }
.slick-track{ position:relative; top:0; left:0; display:block; }
.slick-track:before, .slick-track:after{ display:table; content:''; }
.slick-track:after{ clear:both; }
.slick-loading .slick-track{ visibility:hidden; }
.slick-slide{ display:none; float:left; height:100%; min-height:1px; }
.slick-slide img{ display:block; }
.slick-slide.slick-loading img{ display:none; }
.slick-slide.dragging img{ pointer-events:none; }
.slick-initialized .slick-slide{ display:block; }
.slick-loading .slick-slide{ visibility:hidden; }
.slick-vertical .slick-slide{ display:block; height:auto; border:1px solid transparent; }
.slick-loading .slick-list{ }
.slick-arrow{ position:absolute; z-index:13; top:50%; display:block; width:40px; height:40px; margin-top:-20px; padding:0; cursor:pointer; border:none; background:none; font-size:32px; line-height:40px; text-align:center; border-radius:0; box-shadow:none; color:inherit; -webkit-transition:all .2s linear; -moz-transition:all .2s linear; -o-transition:all .2s linear; transition:all .2s linear; }
.slick-prev{ left:0; }
.slick-next{ right:0; }
.slick-arrow.slick-hidden{ display:none; }
.slick-dots{ position:absolute; bottom:0; display:block; width:100%; margin:0; padding:0; list-style:none; line-height:0; text-align:center; }
.slick-dots li{ display:inline-block; position:relative; margin:0 5px; padding:0; width:8px; height:8px; border:2px solid #069; cursor:pointer; border-radius:100%; -webkit-transition:all .2s linear; -moz-transition:all .2s linear; -o-transition:all .2s linear; transition:all .2s linear; }
.slick-dots li button{ display:none; }
.slick-dots li:hover, .slick-dots li.slick-active{ background:#069; }
.slick-dots [aria-label="1 of 1"]{ display:none !important; }
/* slick END */



