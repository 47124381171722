.box-main{

  &__control{
    margin-top:2.4em;
    margin-bottom:0;
  }

  .g-grid{
    @media(max-width:$width_xs) and (orientation:landscape){
      position:relative;
      &:before{
        content:'';
        position:absolute;
        top:0;
        bottom:0;
        display:block;
        background-color:$clr_7;
        left:-99em;
        right:-99em;
      }

    }
  }

  &__box{
    position:relative;
    @extend %bgi;
    background-color:$clr_7;
    background-image:url(../img/page_raspisanie/bg_box_main.png);
    background-position:right;
    color:$clr_2;
    border-radius:$br_2;
    padding:7em 4em 6em;
  }
  &__text{
    position:relative;
    h1:first-letter{
      text-transform:uppercase;
    }
  }
  &__descr{
    margin-bottom:2.7em;
  }
  &__img{
    @extend %pos_img_content;
    left:100%;
    top:-2em;
    /*  &:not([style]){
        top:50%;
        transform:translateY(-50%);
      }*/
    &::before{position:relative}
  }
  &__circle-decor{
    @extend %pos_img_content;
    @extend %decor-circle;
    @extend %decor-circle_anim;
    position:absolute;
    height:14em;
    width:14em;
    left:100%;
    bottom:-9em;
    top:auto;
    margin-right:4em;

    &:not([style]){
      bottom:-15em;
    }

    &-title{
      font-size:4.375rem;
      margin-bottom:0;
      line-height:1;
    }
  }
  &_raspisanie{
    .box-main{
      &__box{
        padding:4em;
      }

      &__text{
        /* h1 {font-size: 2.625em;}*/
      }

      &__img{
        right:100%;
        left:auto;
        &::before{content:url(../img/page_raspisanie/box_main_raspisanie.png);}
      }
      &__circle-decor{
        right:100%;
      }
    }
  }

  &_mobileapp{
    .box-main{
      &__box{
      }
      &__circle-decor{
        bottom:-13em;
        margin-left:-9em;
      }
      &__img{
        top:-10em;
        &::before{content:url(../img/page_mobilapp/box_main_mobileapp.png);;}
      }
    }
  }
  &_erp{
    .box-main{
      &__box{
        padding-top:5.5em;
      }
      &__circle-decor{
        top:-9em;
        margin-left:6em;
        z-index:2;
        &-title{
          font-size:4.375rem;
        }
      }
      &__img{
        top:-2em;
        left:84%;
        &::before{content:url(../img/page_erp/box_main_erp.png);}

      }
      &__img-anims{
        top:-2em;
        left:84%;
        position:absolute;
      }
      &__img-anim{

        &::before{position:relative}
        &_0{
          position:absolute;
          left:0;
          top:-18%;
          z-index:2;
        }
        &_0:before{
          content:url(../img/img_anim/complex_0_2.png);
        }
        &_1:before{
          content:url(../img/img_anim/complex_1.png);
        }
        &_2{
          position:absolute;
          left:0%;
          bottom:-11%;
          z-index:1;
        }
        &_2:before{
          content:url(../img/img_anim/complex_2.png);
        }
        &_3{
          right:21%;
          bottom:-7%;
          position:absolute;
        }
        &_3:before{
          content:url(../img/img_anim/complex_3.png);
        }
      }
    }
  }

  @include media($width_xs){
    &:after{
      content:'';
      background:no-repeat center/contain;
      display:block;
      margin:3em 0 -4em;
    }
    &_erp:after{
      background-image:url(../img/page_erp/box_main_erp.png);
      padding-top:85%;
      margin:2em -3em -2em;
      margin-left:0em;
    }
    &_mobileapp:after{
      background-image:url(../img/page_mobilapp/box_main_mobileapp.png);
      padding-top:140%;
    }
    &_raspisanie:after{
      margin:2em -4em -1em;
      background-image:url(../img/page_raspisanie/raspisanie_refresh.png);
      padding-top:61%;
      margin-left:-1em;
    }
    & &__box{
      padding:2em 1em;
      margin:-3em -1em 0;
      border-radius:0;
    }
    &__control{
      text-align:center;
      margin-top:13.4em;
      .btn{margin-bottom:1em}
    }
    & &__circle-decor{
      right:auto;
      transform:translateX(-50%) !important;
      left:50%;
      bottom:7em;
      height:11em;
      width:11em;
      margin:0;
      top:auto;
    }
    &__img-anims,
    &__img{display:none}
    &__circle-decor-title{
      font-size:3.375rem;
    }

  }
}