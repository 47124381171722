.works{
  @extend %bgi;
  background-image:url(../img/page_erp/bg_works.jpg);
  color:$clr_2;
  background-position-y:top;
  padding:13em 0 2em;
  min-height:62em;
  margin-bottom:-24em;
  h2{ color:$clr_2 }
  &__list{
    display:flex;
    max-width:1117px;
    margin:6em auto 0;
    justify-content:space-between;
  }
  &__item{
    text-align:center;
    line-height:1.2;
    display:flex;
    flex-direction:column;
    align-items:center;
    position:relative;
    flex:1;
    &:last-child:after{ display:none }

    &:before{
      width:8.438em;
      height:8.438em;
      background:$clr_2 no-repeat center;
      box-shadow:0 0.313em 1.125em rgba(101, 97, 98, 0.77);
      margin-bottom:1em;
      display:inline-block;
      border-radius:50%;
      line-height:8.438em;
      content:'';
    }
    &:after{
      @extend %pos_img_content;
      left:100%;
      margin-top:-1.5em;
      margin-left:-3em;
      content:svg-load('icon_work_arrow.svg');
    }

  }
  &__item_1:before{ background-image:svg-load('icon_works_1.svg'); }
  &__item_2:before{ background-image:svg-load('icon_works_2.svg'); }
  &__item_3:before{ background-image:svg-load('icon_works_3.svg'); }
  &__item_4:before{ background-image:svg-load('icon_works_4.svg'); }

  @include media($width_xs){
    display:none;
    min-height:58em;
    padding-top:10em;
    margin-top:-5em;
    &__list{
      min-width:963px;
      margin-top:1em;
    }
    &__scroll{
      overflow-x:auto;
      margin-right:-15px;
      margin-left:-7px;
      padding-left:15px;
    }
  }
}