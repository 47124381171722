.nav-main{
  &__list{
    display:flex;
  }
  &__item{
    padding:0 .5em;
  }
  &__item_active &__link{border-color:#65cdaa;}
  &__link{ font-weight:500;
    border-bottom:3px solid transparent;
    padding-bottom:3px;
    transition:$trans_border;

    &:hover{
      border-color:$clr_4;
    }
  }
  @include media($width_xs){

    &__list{
      display:block;
    }
    &__item{
      padding:.5em 0;
      border-bottom:1px solid rgba($clr_1, .1);
    }
    &__link:hover{border-color:transparent;}
    &__item_active &__link{border-color:transparent;color:$clr_4}
  }
}



