fieldset{ border:0; padding:0; margin:0 }
fieldset li{ margin-bottom:1.5em; }
button, input, select, textarea{
  &:focus{ outline:none; }
}
/*select, textarea,
[type]:not([type="checkbox"]),[type]:not([type="radio"]),[type]:not([type="button"]),[type]:not([type="submit"]),[type]:not([type="reset"]),[type]:not([type="range"]),[type]:not([type="file"]),[type]:not([type="image"]){
  padding:1em 2em;
  border:1px solid gray;
  color:black;
  background-color:white;
  text-align:center;
  width:100%;
}*/
input[type=submit]:not([value]){outline:2px solid red;}
input{
  @include placeholder{ color:$clr_1; }

  &.input-error, &.input-error:focus{border-color:red;}
}
textarea{ resize:vertical }
button, label, input[type="submit"], input[type="reset"]{ cursor:pointer; }
label{ display:block;
  &.input-error{display:none !important;}
}
.btn{
  background:$clr_3;
  border:1px solid transparent;
  color:$clr_1;
  border-radius:2em;
  display:inline-block;
  vertical-align:top;
  padding:1em 2em;
  box-shadow:0 0.75em 1em -.5em rgba(240, 31, 85, 0.39);
  font-size:0.813em;
  font-weight:700;
  text-transform:uppercase;
  transition:$trans;
  @extend %btn-hover;
  text-align:center;
  &_sz{
    text-transform:none;
    font-size:1em;
  }
  &_plr{
    padding-left:4em;
    padding-right:4em;
  }

  @include media($width_xs){
    &:not(&-icon_mod){width:100%;justify-content:center;}
  }
}
.form-dark{
  max-width:492px;
  &__label{ display:block; color:$clr_2; font-size:0.875em; margin-bottom:.3em; }
  &__field{
    padding:1em 1em;
    border:1px solid #747d85;
    color:$clr_2;
    background-color:$clr_5;
    transition:$trans;
    width:100%;
    border-radius:$br_1;
    @include placeholder{ color:#747d85; }
    &:focus{
      border-color:$clr_4;
    }
  }

  @include media($width_xs){
    max-width:100%;
    &__field{
      background-color:$clr_2;
      border-color:$clr_2;
    }
  }
}




.form-timer{
  background:$clr_2;
  border-radius:$br_1;
  margin-top:3em;
  &:before{
    border-radius:$br_1 $br_1 0 0;
    content:'';
    display:block;
    @extend %bgi;
    background-image:url(../img/bg/bg_form_timer.jpg);
    height:140px;
  }
  &__inner{
    padding:1em;
  }
}

.btn-arrow{
  border-radius:50%;
  display:inline-block;
  vertical-align:top;
  border:1px solid transparent;
  line-height:1;
  background-image:svg-load('icon_arrow.svg', fill="#b59c38");
  @extend %btn-arrow;
  @extend %bgi;
  transition:$trans;
  background-size:10px 17px;
}
.btn-icon{
  position:relative;
  font-size:1em;
  text-transform:none;
  display:inline-flex;
  align-items:center;
  font-weight:500;
  line-height:1.2;
  border-radius:3em;
  border:0;
  color:$clr_2;
  background:#09a16e;
  background-image:linear-gradient(to top, #09a16e 0%, #87c443 49%, #95eb35 100%);
  box-shadow:0 0.688em 0.813em -.5em rgba(181, 228, 59, 0.84);
  &:after{
    margin:-.5em -1em -.5em 1em;
    content:svg-load('icon_head_2.svg');
    transition:$trans;
  }
  &:hover:after{
    transform:translateX(-5%);
  }

  &_no_bsh{
    box-shadow:none;
  }
  &_mod{
    padding:.5em 2em;
    &:after{
      content:svg-load('icon_gar.svg');
      margin:0em -1.5em -.3em 1.25em;
    }
  }
}
.btn-gr{
  @extend .btn-icon;
  text-transform:uppercase;
  padding:1.8em 2em;
  &:after{display:none}
}

.btn-icon-arrow{
  position:relative;
  font-size:1em;
  text-transform:none;
  display:inline-flex;
  align-items:center;
  font-weight:400;
  line-height:1.2;
  border-radius:3em;
  &:after{
    @extend .btn-arrow;
    content:'';
    background-color:$clr_2;
    margin:0 -1em 0 2.9em;
    background-image:svg-load('icon_arrow.svg', fill=$clr_4);
  }
  &:hover:after{
    transform:translateX(-5%);
  }

  @include media($width_xs){
    margin-left:.5em;
  }
}

.subs-input{
  border:1px solid transparent;
  background-color:$clr_2;
  border-radius:$br_1;
  color:$clr_1;
  font-size:1em;
  width:100%;
  padding:1em 2em;
  line-height:1;
  @include placeholder{ color:rgba($clr_1, .5) }
  @extend %bsh_2
}

.form-timer-input{
  border:1px solid transparent;
  background-color:#e8e8e8;
  border-radius:$br_1;
  color:#585858;
  font-size:0.75em;
  width:100%;
  padding:1em 1em;
  line-height:1;
  @include placeholder{ color:#585858; }

}
.form-timer-btn{
  border:1px solid transparent;
  border-radius:2em;
  color:white;
  font-size:0.875em;
  font-weight:500;
  text-transform:uppercase;
  background-color:#ff3000;
  width:100%;
  padding:1.5em 2em;
  &:hover{
    opacity:.8;
  }
}
.form-line{

  & &__box{
    background-image:url(../img/bg/bg_statistic.png);
    background-position:52% 24%;
    background-size:234%;
  }

  &_wave{
    @extend %bgi;
    padding:16em 0 11em;

    &.g-section-margin{
      margin-bottom:-5em
    }
    background-image:url(../img/bg/bg_decor_wave.png);
    background-size:auto;
    background-position:top center;

    &:before{ }
  }
  &__text{
    padding-right:25%;
    position:relative;
  }
  &__box{
    -webkit-backface-visibility:hidden;
    -moz-backface-visibility:hidden;
    -webkit-transform:translate3d(0, 0, 0);
    -moz-transform:translate3d(0, 0, 0);

    overflow:hidden;
    position:relative;
    padding:3em 3em;
    border-radius:$br_2;
    background:$clr_5;
    color:$clr_2;
    &:before{
      @extend .decor-circle;
      content:'';
      width:800px;
      height:800px;
      position:absolute;
      left:-26%;
      bottom:-49%;
      animation:form-line 2s linear infinite alternate;
    }

    @include media($width_xs){
      padding:1.3em 1.5em 1.7em;
      &:before{display:none !important;}
    }



  }
  &__list{ margin-bottom:-1em }
  &__item{
    margin-bottom:1em;
  }

  &_square{
    .form-line{
      &__box{
        &:before{
          left:-39%;
          top:-35em;
          bottom:auto;
        }
      }
      &__text{ padding-right:0; }
    }
    .form-dark{
      max-width:100%;

      &__field{ background-color:$clr_2; border-color:$clr_2; color:$clr_1;
      }
    }
  }

  @include media($width_xs){
    &_wave{
      padding:0em 0 7em;
    }

    &__text{padding-right:inherit;margin-bottom:1em}
  }
}
@keyframes form-line{
  0%{ transform:rotate(0deg); }
  100%{ transform:rotate(-180deg); }
}
.form-box{
  &__btn{
    margin-top:1.5em;
  }
  &__box{
    @extend %bgi;
    border-radius:$br_2;
    padding:7em 0;
    background-image:url(../img/bg/bg_statistic.png);
    background-color:$clr_5;
    color:$clr_2;
  }
  &__form{
    position:relative;
    fieldset{
      @extend %g-col-mw_4;
    }
    .form-box_mac2 &:before{ content:url(../img/page_erp/form-box_mac_2.png); }
    &:before{
      position:absolute;
      right:100%;
      top:50%;
      transform:translate(0, -50%);
      margin-right:20.2%;
      content:url(../img/page_raspisanie/form-box_mac.png);
    }

  }
  @include media($width_xs){
    &__btn{margin-top:0}
    &__box{padding:2em 2em;}
    &__form:before{display:none}
  }
}
.chr{
  &__input{ display:none }
  &__text{
    padding-left:30px;
    position:relative;
    display:block;
    &:before{
      content:'';
      position:absolute;
      left:0;
      top:0;
      width:15px;
      height:15px;
      background-color:transparent;
      border-radius:50%;
      border:2px solid $clr_2;
      transition:$trans;
    }
    &:after{
      content:'';
      position:absolute;
      left:0;
      top:0;
      width:5px;
      height:5px;
      background-color:$clr_2;
      border-radius:50%;
      transform:translate(.3em, .3em);
      opacity:0;
      visibility:hidden;
      transition:$trans;
    }
    &:after, &:before{
      margin-top:.5em;
    }
  }
  &__input:checked + &__text:after{
    opacity:1;
    visibility:visible;
  }
}
.btn-arrow-push{
  position:fixed;
  @extend .btn-arrow;
  left:0;
  bottom:0;
  margin:2em;
  border:0;
  background-size:auto;
  width:40px;
  min-width:40px;
  height:40px;
  background-image:none;
  cursor:pointer;
  z-index:99;
  transform:translateY(10em);

  &:before{
    background-image:svg-load('icon_arrow.svg', fill="#b59c38", width=10, height=20);
    @extend %abs;
    transform:rotate(-90deg);
    @extend %bgi;
    background-size:auto;
  }

  @include media($width_xs){
    display:none;
  }

  .is-scrl2top-visible &{
    transform:translateY(0);
  }
}
