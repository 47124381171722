.section-text{
  &-control{
    margin-top:2.5em;
  }
  &_online-group{
    padding-bottom:2em;

    @include media($width_md){

      &.g-section-margin_big{
        padding-bottom:0;
        margin:6em 0;
      }
    }
  }
  &_online-personal{
    padding-bottom:3em;
    @include media($width_md){

      &.g-section-margin_big{
        padding-bottom:0;
        margin:6em 0;
      }
    }
  }
  &_img-abs_left &__img-abs{ left:2%;}
  &_img-abs_left &__text{ margin-left:56%;}

  &_img-abs_right &__img-abs{left:56%;}
  &_img-abs_right &__text{margin-left:14%;}

  &__img-abs{
    @extend %pos_img_content;
    left:0;
  }

  &_refresh{ padding-bottom:2em; }
  &_refresh img{max-width:136%}
  &_refresh &__img-abs{ margin-top:1.5em; }

  &_reshen &__img-abs{
    margin:1em -4em;

    .img-complex{
      &::before{position:relative}
      &_0{
        position:absolute;
        left:3%;
        top:17%;
        z-index:2;
      }
      &_0:before{
        content:url(../img/img_anim/complex_0_3.png);
      }
      &_1:before{
        content:url(../img/img_anim/complex_1_2.png);
      }

    }
  }
  &_club &__img-abs{
    left:-49px;
    margin-top:3em;

    .img-complex{
      &::before{position:relative}
      &_0{
        position:absolute;
        left:0;
        top:-22%;
      }
      &_0:before{
        content:url(../img/img_anim/complex_0.png);
      }
      &_1:before{
        content:url(../img/img_anim/complex_1.png);
      }
      &_2{
        position:absolute;
        left:13%;
        bottom:-5%;
      }
      &_2:before{
        content:url(../img/img_anim/complex_2.png);
      }
      &_3{
        right:23%;
        bottom:-6%;
        position:absolute;
      }
      &_3:before{
        content:url(../img/img_anim/complex_3.png);
      }
    }

  }
  &_evator{ padding-top:2em;
    padding-bottom:1em; }
  &_evator &__text{ width:auto;
    max-width:439px; }
  &_evator &__img-abs{
    left:50%;
    width:100%;
    max-width:654px;

    @include media($width_xs, 1){
      transform:translateX(-90%);
    }
  }

  &_includes{ padding:11em 0 4em;}
  &_includes &__text{ width:32%; width:auto;max-width:439px; }
  &_includes &__img-abs{ left:13.5%;
    margin-top:1em;
    left:50%;
    width:100%;
    max-width:654px;

    @include media($width_xs, 1){
      transform:translateX(-90%);
    }
  }

  &_mobilapp{ padding:8em 0 4em;}
  &_mobilapp &__img-abs{
    margin-top:2.5em;
  }
  &_voz{
    padding:5em 0 13.5em;
  }
  &_voz &__img-abs{
    left:57.5%;
    top:56%;
  }

  &_other{
    position:relative;
    .g-col{position:static}
    @include media($width_xs){
      padding-bottom:6em !important;
      .section-text-control{
        position:absolute;
        top:100%;
        margin-top:-3em;
        flex-direction:column;
        right:1em;
        left:1em;
        .btn{
          margin:0 0 1em;

        }
      }
      .box-img-circle{margin-top:2em}
    }
  }

  @include media($width_xs){
    padding:0;

    &-timetable-orders-xs{display:flex;flex-flow:column}
    &-timetable-orders-xs &{order:-1; margin:2em 0;}
    &-timetable-orders-xs .product-target{ margin:2em 0;}



    & &__text{
      margin:0;
      width:100%;
    }
    &__img-abs{
      position:static;
      transform:translate(0);
      margin-bottom:3em;
      display:none;
    }

    &_evator &__img-abs{
      display:block;
    }
    &_refresh{
      padding-bottom:0;
      img{max-width:100%}
    }
  }
}