/*! header*/
.header{
  z-index:333;
  box-shadow:0 0 20px rgba(0, 0, 0, 0.5);
  background:$clr_2;
  padding-bottom:.3em;



  @extend %abs;
  position:fixed;
  bottom:auto;

  .is-header-hide &{
    transform:translateY(-110%);
  }

  &__top{
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:.2em 0 0;
  }
  &__main{
    display:flex;
    justify-content:space-between;
    align-items:center;
  }
  &__logo{
    display:block;
    flex:1 0 auto;
    max-width:235px;
    margin-top:.7em;
    .logo_1{ fill:#65cdaa; }
    .logo_2{ fill:#283643; }
    &:hover{
      .logo_1{ fill:#283643; }
      .logo_2{ fill:#65cdaa; }
    }
  }
  .header__hr{
    margin:.2em 0 0;
  }
  &__control{ display:flex; padding-top:.2em;}
  &__control-item{ margin-left:3.3em; }
  &__control-link{
    transition:$trans_border;
    .g-link-js {
      transition:$trans_border;
    }
    font-size:0.75em;
    @extend %link-reset;
    @extend %icon-text;
    align-items:end;
    position:relative;
    padding-left:2.5em;
    &:before{@extend %pos_img_content;left:0; }

    &.icon-ak:before{height:14px;}
    &.icon-picture:before{height:17px;}
    &.icon-phone:before{height:17px;}
  }
  &__control-tel{
    font-weight:700;
    font-size:0.875em;
  }

  &__sign{
    display:flex;
    margin-left:6em;
    &-item{
      display:flex;
      a{padding-left:0}
    }
    &-item_left::after{
      content:'/';
      margin:0 .3em;
      color:#d6d6d6;
      transform:scaleY(.8);
      position:relative;
      top:-.1em;
    }
  }

  @include media($width_xs){

    bottom:0;
    display:flex;
    flex-direction:column;
    text-align:center;
    width:100%;
    max-width:calc(100% - 85px);
    left:auto;
    right:-110%;
    font-size:1.1em;

    & &__soc, &__control-item:not(:last-of-type){

    }
    .is-nav-main-opened &{right:0;}
    .is-header-trans &{transition:$trans}

    &__main{
      display:block;
    }
    &__btn.btn, &__hr{
      display:none;
    }
    &__grid-top, &__soc{
      order:1;width:100%;
      margin-top:0;
    }
    &__top, &__control{ margin-top:-.4em; flex-direction:column;width:100%; align-items:center;}
    &__control-item:not(&__control-item_sign){display:none}
    &__control-item, &__sign{
      margin-left:0;
      & + &{
        margin-top:1em;
      }

    }
    &__logo{
      display:none;
    }
  }
}
/*! END header*/