.rates{
  &__form{
    position:relative;
    z-index:3;
  }
  &__form_height{
    height:563px;
    overflow:hidden;
    margin-bottom:-.5em;
    margin-top:-1em;
  }

  &__text-decor{
    position:relative;
    color:$clr_4;
    text-align:center;
    margin:4em auto 0;
    &-title{
      font-size:2em;
      line-height:1;
    }
    &-text{
      margin-bottom:1.5em;
    }
    &-numb{
      font-size:3.750em;
    }
    &-link{
      font-size:1.250em;
    }
    &-dev{
      font-size:1.2em;
    }
    &_dev{
      font-size:1.2em;
      &:after{
        content:url(../img/rates/rates-decor-arrow.png);
        transform:translate(-18px, -18px);
        position:absolute;
        right:0;
        top:100%;
      }
    }
  }

  &__list{
    max-width:853px;
    max-height:500px;
    display:flex;
    margin:4em auto 0;
    width:100%;
  }
  &__box{
    position:relative;
    color:$clr_2;
    padding:1.7em 12%;
    line-height:1.2;
    &:before{
      @extend %abs;
      @extend %bsh_2;
      transition:$trans;
      box-shadow:1.5px 2.6px 49px rgba(0, 0, 0, 0.34);
      border-radius:$br_2;
      z-index:-1;
    }
  }
  &__item{
    margin:0;
    flex:1;
  }
  &__label{
    font-size:0.875em;
    display:block;
  }
  &_erp &__name{font-size:1.5em}
  &_erp &__select{margin-bottom:1em}
  &_erp &__select-list{padding-bottom:2em}
  &__name{
    font-size:1.75em;
    color:$clr_2;
    font-weight:400;
    margin-top:.2em;
  }
  &__select{
    padding-top:1.2em;
    margin-bottom:2.5em;
    cursor:pointer;
    position:relative;
  }
  &__sale_select{margin-left:2.2em;margin-top:1em;font-size:.9em;}
  &__sale_select &__sale-name{font-size:1.125em;
    &:before{ left:-.2em;right:-.3em; font-weight:400;}
  }
  &__hide{
    visibility:hidden;
    opacity:0;
  }
  &__sale{
    text-decoration:none;
    display:block;
    margin-bottom:.5em;
    &-name{
      font-size:1.5em;
      position:relative;
      margin-right:.25em;

      &:before{
        content:'';
        position:absolute;
        left:-.1em;
        right:-.2em;
        top:50%;
        transform:translate(0, -50%) rotate(-10deg);
        display:block;
        height:2px;
        background-color:#ff4c4c;
      }

    }
  }
  &__select-name{
    position:relative;
    display:block;
    font-weight:300;
    border-color:rgba($clr_2, .5);

  }
  &__select-arrow{
    @extend %pos_img_content;
    right:0;
  }
  &__select-list{
    transform:translateX(-100%);
    opacity:0;; visibility:hidden;
    transition:$trans;
    @extend %abs;
    @extend .rates__box;
    position:absolute;
    top:100%;
    bottom:auto;
    margin:.5em -4em;
    padding:1em 2em 1.2em;
    z-index:10;
    line-height:1;
    &:before{
      margin:0 !important;
    }
    &:after{ display:none }
    span{ line-height:1 }
  }
  &__select.open &__select-list{ transform:translateX(0); visibility:visible; opacity:1 }
  &__select-item{
    margin-bottom:0;

    & + &{
      border-top:1px solid rgba($clr_2, .3);
      padding-top:1em;
      margin-top:1em;
    }
  }
  &__saving{
    line-height:1.2;
    ul li{ margin-bottom:0; }
    ul li:before{ border-color:$clr_2 }
  }
  &__item{ position:relative; }
  /*  &__item_1{transform:translateX(2em)}
    &__item_3{transform:translateX(-2em)}*/
  &__item_2{ z-index:1 }

  @include media($width_xs, 1){
    .rates__list:not(:hover ) &__item_2 &__box:before, &__item:hover &__box:before{ margin:-2em -1em }
    .rates__list:not(:hover ) &__item_2 &__box:after, &__item:hover &__box:after{ margin:-2em -1em }
    .rates__list:not(:hover ) &__item_2 &__btn-wrap, &__item:hover &__btn-wrap{ background-color:#f6f7f8; margin-left:-1em; margin-right:-1em }
    .rates__list:not(:hover ) &__item_2 &__btn, &__item:hover &__btn{
      position:relative;
      font-size:1em;
      display:inline-flex;
      align-items:center;
      font-weight:500;
      line-height:1.2;
      border-radius:3em;
      border:0;
      color:$clr_2;
      background:#09a16e;
      background-image:linear-gradient(to top, #09a16e 0%, #87c443 49%, #95eb35 100%);
      box-shadow:0 0.688em 0.813em -.5em rgba(181, 228, 59, 0.84);
      text-transform:uppercase;
      padding:1.8em 4em;
    }
  }


  &__item_2 &__box:after{
    content:url(../img/rates/hit.png);
    right:0;
    top:0;
    transform:translate(5px, -5px);
    transition:$trans;
    position:absolute;
  }

  &__item_1 &__box:before{ background-color:$clr_5; background-image:linear-gradient(150deg, #546f89 0%, #293744 100%); }
  &__item_2 &__box:before{ background-color:#00bdb1; background-image:linear-gradient(150deg, #06bcb0 0%, #05a79c 100%); }
  &__item_3 &__box:before{ background-color:#0965b7; background-image:linear-gradient(150deg, #68aff6 0%, #1467b4 100%); }

  &__item:hover{ z-index:2 }

  &__btn{
  }
  &__btn-wrap{
    transition:$trans;
    margin-top:-2em;
    text-align:center;
    padding:7em 0 2em;
    border-radius:$br_2;
    position:relative;
    z-index:-2;
  }

  &_erp &__btn-wrap{display:none}

  &__adaptive{
    display:none;
  }
  & &__btn-arrow{
    display:block;
    margin:-1em auto;
    transform:rotate(90deg);

  }

  @include media($width_xs){

    &__box{

      padding:1.7em 2em;

    }
    &__select-list{
      margin-left:-1em;
      margin-right:-1em;
      padding-left:1em;
      padding-right:1em;
    }
    &__item + &__item{margin-top:4em}
    &__list{
      display:block;
      max-height:none;
    }
    &__text-decor{
      margin-top:1em;
    }

    &__adaptive{
      display:block;
    }
    &__text-decor_dev{
      margin-bottom:-3em;
    }
    &__text-decor_dev:after{
      display:none;
    }

    &__form_height{
      height:auto;
      overflow:visible;
    }
    &__btn-wrap{
      margin-top:0;
      padding:1em 0 0em;
    }

  }

}
.rates-table{
  position:relative;
  margin-top:3.5em;
  &:before{
    content:'';
    position:absolute;
    width:25.8%;
    left:50%;
    background:#f6f7f8;
    display:block;
    top:-10em;
    bottom:-1em;
    border-radius:0 0 $br_2 $br_2
  }
  &__item{
    & + &{
      /*  margin-top:2em;*/
    }
  }
  &__name{
    font-weight:500;
    margin-bottom:0;
    line-height:1.4;
  }

  &__row{
    border-bottom:1px solid #e2e2e2;
    padding:.6em 0;
    position:relative;
    line-height:1.4;
  }

  &__text{
    @extend .g-col_md_4;
    margin-bottom:0;
    position:relative;
    padding-right:1em;
  }
  &__point{
    width:10px;
    height:10px;
    border:2px solid $clr_4;
    border-radius:50%;
    @extend %pos_img_content;
    top:1.25em;
    z-index:2;

    &_text{
      width:auto;
      height:auto;
      transform:translate(-50%, -50%);
      border:0;
      font-size:0.875em;
      font-weight:300;
    }

    &_1{ left:37% }
    &_2{ left:62.5% }
    &_3{ left:87.5%; }

  }
  &__point_2 &__bg:after{
    @extend %abs;
    background:$clr_6;
    margin:-5em;
    z-index:-1;
  }
  &__row_parent{ cursor:pointer }
  &__row_parent > &__text:after{
    content:svg-load('icon_arrow.svg', fill="#888888", width=5, height=10);
    position:absolute;
    @extend %pos_img_content;
    transform:rotate(90deg) translate(-10px, -50%);
    left:100%;
    margin-left:-1em;
  }

  &__row_clock &__point{
    border-color:transparent;
    &:before{
      content:svg-load('icon_clock.svg');
      position:absolute;
      left:50%;
      top:50%;
      margin-top:.2em;
      transform:translate(-50%, -50%);
    }
  }
  &__row_clock &__text{
    color:#818181;
  }

  &__row.open{
    padding-bottom:0;
    border-bottom:0;
  }
  &__row.open &__rows_children{ display:block }
  &__rows_children{ display:none; border-top:1px solid #e2e2e2;margin-top:.6em;}
  &__rows_children &__text{
    padding-left:30px;
    position:relative;
    font-size:.875em;
    &:before{
      display:block;
      content:"";
      width:10px;
      height:10px;
      border:2px solid $clr_4;
      border-radius:50%;
      position:absolute;
      top:.3em;
      left:0;
    }
  }
  &__small{
    font-size:0.75em;
    font-weight:500;
  }
  &__btns .g-col{
    flex-direction:column;
    align-items:center;
    height:140px;
  }
  &__col-btn-main{
    transform:translateY(1em);
  }
  &__col-btn-main &__btn{
    padding-left:3em;
    padding-right:3em;
  }
  &__btns &__btn{
    margin-top:auto;
    margin-bottom:1.5em;
  }

  @include media($width_xs){
    display:none;
  }
}
.rates__adaptive{
  &.open{
    .rates__btn-arrow{
      transform:rotate(-90deg);
      margin-top:1em;
    }
  }
  &-text{display:none; margin:2em 0 0;}
  &.open &-text{display:block}
  .rates-table{
    margin-top:1.5em;
    display:none;
    &::before{display:none}
    &__btns{
      margin-top:2em;
      display:block;
      .g-col{height:auto}
      p{display:none}
    }
    &__text{
      width:100%;
      padding-right:0;
    }
    &__point{display:none}
  }
}