.subs{
  &__box{
    position:relative;
    @extend %bsh_2;
    background:$clr_6;
    border-radius:$br_2;
    padding:2em 4em;
    display:flex;
    flex-direction:column;
    min-height:300px;
    justify-content:center;
  }
  &__circle, &__email{
    @extend %decor-circle;
    /* Style for "Ellipse 13" */
    width:16.25em;
    height:16.25em;
    @extend %abs;
    left:auto;
    top:50%;
    /*   transform: translate(44%, -52%);*/

    margin-right:-7em;
    margin-top:-8.125em;
    /*  &[style]{
        margin-top:-10.125em;
      }*/
  }
  &__email{
    @extend %bgi;
    background-image:svg-load('icon_mail_decor_big.svg');
    background-size:auto;
    padding:4em;
    box-sizing:content-box;
    /*    transform: translate(52%, -53%);*/
    margin-top:-12.5em;
    margin-right:-12em;
    /* &[style]{
       margin-top:-13.125em;
     }*/
    .subs_mobil &{
      background-image:svg-load('icon_mail_decor_big_mobil.svg');
      margin-top:-15em;
    }

  }
  &__group{
    display:flex;
    align-items:center;
    max-width:420px;
    margin:-2em auto 0;
    top:100%;
    position:absolute;
    left:0;
    right:0;
  }
  &__btn{ flex:1 0 auto; margin-left:-2em;
    margin-right:2em; }

  @include media($width_xs){
    &__group{padding-left:2em}
    &__box{
      padding: 2em 2em 3em;
      min-height:auto;
    }
    &__circle, &__email{
      display:none;
    }
  }

}