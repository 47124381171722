.box-present{
  padding:6em 0;
  &__box{
    background:$clr_5;
    color:$clr_2;
    padding:3em;
    display:flex;
    align-content:center;
    max-width:950px;
    margin:0 auto;
    border-radius:$br_2;
    position:relative;
    &:before{
      content:url(../img/page_mobilapp/box-present.png);
      position:absolute;
      left:0;
      top:72%;
      transform:translate(-38%, -50%);
      z-index:1;
    }

  }
  &__title{
    margin-right:2em;
    line-height:1.5;
    margin-top:.7em;
    margin-left:auto;
  }
  &__btn{
    box-shadow:none;
  }

  @include media($width_xs){
    display:none;
    padding:0;
    &__box{
      display:block;
      &::before{display:none}
      padding:1em;
    }
    &__title{
      margin-right:0;
      margin-top:0;
    }
  }
}