/*! plugins */
@import '../../../bower_components/normalize.css/normalize.css';
@import "colorbox.css";
@import "slick.css";
/*@import "nouslider.css";
@import "scrollbar.css";
@import "selectbox.css";*/
.spinner{ position:relative }
.spinner:before{
  content:'';
  box-sizing:border-box;
  position:absolute;
  top:50%;
  left:50%;
  width:20px;
  height:20px;
  margin-top:-10px;
  margin-left:-10px;
  border-radius:50%;
  border-top:2px solid #07d;
  border-right:2px solid transparent;
  animation:spinner .6s linear infinite;
}
@keyframes spinner{
  to{ transform:rotate(360deg) }
}
@-moz-keyframes spinner{
  to{ transform:rotate(360deg) }
}
@-ms-keyframes spinner{
  to{ transform:rotate(360deg) }
}
@-o-keyframes spinner{
  to{ transform:rotate(360deg) }
}
@-webkit-keyframes spinner{
  to{ transform:rotate(360deg) }
}
/* chosen */
/* Dropdown control */

/*! end plugins */










