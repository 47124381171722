.request-decor{
  &__box{
    background:$clr_5;
    color:$clr_2;
    max-width:980px;
    margin:0 auto;
    border-radius:$br_2;
    padding:4em 9%;
    position:relative;
    &:after{
      @extend %pos_img_content;
      content:url(../img/page_mobilapp/request-decor-photo.png);
      right:-1em;
      margin-top:5em;
      top:0;
      animation:scale 5s linear infinite;
    }
  }
  &__img{
  }
}