/*** PRINT STYLES ***/
@media print{
  @page{
    margin:.5cm;
  }
  *{ color:#333 !important; background:#fff !important; text-shadow:none !important; }
  a, a:visited{ border:0 !important; color:#333 !important; text-decoration:underline !important; }
  abbr:after{ content:" (" attr(title) ")"; }
  pre, blockquote{ border:1px solid #333; page-break-inside:avoid; }
  thead{ display:table-header-group; }
  tr, img{ page-break-inside:avoid; }
  p, h2, h3{ orphans:3; widows:3; }
  h2, h3{ page-break-after:avoid; }
}