//Clearfix
@mixin clearfix() {
  zoom:1;
  &:before,
  &:after{
    content:" "; // 1
    display:table; // 2
  }
  &:after{
    clear:both;
  }
}
@mixin lf-child_reset() {
  &:last-child{
    margin-bottom:0;
  }
  &:first-child{
    margin-top:0;
  }
}
@mixin make-grid-columns-max-w($count,$grid-width) {
  @include make-grid-columns-max-w-func-one($count, '_xs');

  @media (min-width:$width_xs + 1px) {
    @include make-grid-columns-max-w-func-one($count, '_md');
  }
  @media (min-width:$width_md + 1px) {
    @include make-grid-columns-max-w-func-one($count, '_lg');
  }
  @media (min-width:$width_lg + 1px) {
    @include make-grid-columns-max-w-func-one($count, '');
  }

}
@mixin make-grid-columns-px($row,$grid-columns,$grid-gutter-width,$wm) {
  .g-col{
    float:left;
    position:relative;
    min-height:1px;
    margin-left:#{$grid-gutter-width};
    &.first,
    &:first-child{
      clear:left;
      margin-left:0;
    }
  }
  @for $i from 1 through $grid-columns{
    .g-col_md_#{$i}{
      width:(($wm - ($grid-gutter-width * ($grid-columns - 1))) / $grid-columns) * $i + $grid-gutter-width * ($i - 1);
    }
  }
  .g-col_md_#{$grid-columns}{
    margin-left:0;
  }
}
@mixin make-grid-columns-max-w-func-one($count, $prefix:'') {
  @for $i from 0 through $count{
    $multiplier:$i / $count *100;

    %g-col-mw#{$prefix}_#{$i}, .g-col-mw#{$prefix}_#{$i}{
      max-width:(($grid-width/100)*$multiplier) - $grid-gutter;
      //left:(100 * $multiplier)/$grid-width;

    }


  }
}
@mixin make-grid-ofleft($grid-columns,$grid-gutter-width) {
  @for $i from 1 through $grid-columns - 1{
    .ofleft-#{$i}{
      margin-left:(($grid-width - ($grid-gutter-width * ($grid-columns - 1))) / $grid-columns) * $i + $grid-gutter-width * ($i - 1) + $grid-gutter-width;
    }

    .ofleft-#{$i}:first-child,
    .first.ofleft-#{$i}{
      margin-left:(($grid-width - ($grid-gutter-width * ($grid-columns - 1))) / $grid-columns) * $i + $grid-gutter-width * ($i - 1);
    }
    //.#{$row} .ofleft-#{$i} {
    //margin-left: (($grid-width - ($grid-gutter-width * ($grid-columns - 1))) / $grid-columns) * $i + $grid-gutter-width * ($i - 1) + $grid-gutter-width * 2;
    //}

  }
}
@mixin func-one($count, $prefix:'') {
  @for $i from 0 through $count{
    $multiplier:$i / $count;

    .g-col#{$prefix}_#{$i}{
      width:(100% * $multiplier);
    }
    .g-col-offset#{$prefix}_#{$i}{
      margin-left:(100% * $multiplier);
    }

  }
}
@mixin global-class($prefix:'') {

  .g-scroll-line_a#{$prefix}{overflow-x:auto}
  .g-scroll-line_i#{$prefix}{overflow-x:inherit}
  .g-hide#{$prefix}{display:none !important;}
  .g-show#{$prefix}{display:block !important;}
  .g-show_ib#{$prefix}{display:inline-block !important;}
  .g-td_u#{$prefix}{ text-decoration:underline }
  .g-td_n#{$prefix}{ text-decoration:none }
  .g-tt_u#{$prefix}{ text-transform:uppercase; }
  .g-fw_9#{$prefix}{ font-weight:900 }
  .g-fw_8#{$prefix}{ font-weight:800 }
  .g-fw_7#{$prefix}{ font-weight:700 }
  .g-fw_6#{$prefix}{ font-weight:600 }
  .g-fw_5#{$prefix}{ font-weight:500 }
  .g-fw_4#{$prefix}{ font-weight:400 }
  .g-fw_3#{$prefix}{ font-weight:300 }
  .g-fw_2#{$prefix}{ font-weight:200 }
  .g-fw_1#{$prefix}{ font-weight:100 }

  .g-ta_c#{$prefix}{ text-align:center }
  .g-ta_r#{$prefix}{ text-align:right }
  .g-ta_l#{$prefix}{ text-align:left }
  .g-va_t#{$prefix}{ vertical-align:top }
  .g-va_m#{$prefix}{ vertical-align:middle }
  .g-va_b#{$prefix}{ vertical-align:bottom }

  .g-f_r#{$prefix}, .g-col.g-f_r#{$prefix}{ float:right }
  .g-f_l#{$prefix}{ float:left }
  .g-f_n#{$prefix}{ float:none; }

  .g-w_a#{$prefix}{ width:auto }
  .g-miw_n#{$prefix}{ min-width:none }
  .g-maw_n#{$prefix}{ max-width:none }
  .g-mlr_auto#{$prefix}{margin-left:auto;margin-right:auto;}

  .g-row_narrow#{$prefix}{
    margin-left:-10px;
    margin-right:-10px;
    .g-col{
      padding-left:10px;
      padding-right:10px;
    }
  }

  .g-col-pl_0#{$prefix}{ padding-left:0 }
  .g-col-pr_0#{$prefix}{ padding-right:0 }
  .g-col_a#{$prefix}{ width:auto }

  .g-flex_1#{$prefix}{ flex:1 }
  .g-d_f#{$prefix}{ display:flex }
  .g-jc_fs#{$prefix}{ justify-content:flex-start; }
  .g-jc_fe#{$prefix}{ justify-content:flex-end; }
  .g-jc_c#{$prefix}{ justify-content:center; }
  .g-jc_sb#{$prefix}{ justify-content:space-between; }
  .g-jc_sa#{$prefix}{ justify-content:space-around; }
  .g-jc_se#{$prefix}{ justify-content:space-evenly; }
  .g-as_fs#{$prefix}{ align-self:flex-start }
  .g-as_fe#{$prefix}{ align-self:flex-end }
  .g-as_c#{$prefix}{ align-self:center }
  .g-as_b#{$prefix}{ align-self:baseline }
  .g-as_s#{$prefix}{ align-self:stretch }
  .g-ai_fs#{$prefix}{ align-items:flex-start }
  .g-ai_c#{$prefix}{ align-items:center }
  .g-ai_b#{$prefix}{ align-items:baseline }
  .g-or_1#{$prefix}{ order:1 }
  .g-or_0#{$prefix}{ order:0 }
  .g-flw#{$prefix}{ flex-flow:row wrap }
  .g-fd_c#{$prefix}{ flex-direction:column }
  /*Flex END*/

}
@mixin make-grid-columns-persent($count,$gutter) {
  .g-col{
    float:left;
    position:relative;
    min-height:1px;
    width:100%;
    padding-left:$gutter/2;
    padding-right:$gutter/2;
    &.first,
    &:first-child{
      clear:left;
    }
  }

  @include func-one($count, '_xs');
  @include global-class('_xs');


  @media (min-width:$width_xs + 1px) {

    @include func-one($count, '_md');
    @include global-class('_md');

  }
  @media (min-width:$width_md + 1px) {
    @include func-one($count, '_lg');
    @include global-class('_lg');

  }
  @media (min-width:$width_lg + 1px) {
    @include func-one($count, '');
    @include global-class();
  }
}
@mixin font-face($font-name,$font-file,$font-style,$font-weight) {
  @font-face{
    font-family:'#{$font-name}';
    src:url('/fonts/#{$font-file}.eot'); /* IE9 Compat Modes */
    src:url('/fonts/#{$font-file}.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/#{$font-file}.woff') format('woff'), /* Modern Browsers */
    url('/fonts/#{$font-file}.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/#{$font-file}.svg##{$font-file}') format('svg'); /* Legacy iOS */
    font-style:#{$font-style};
    font-weight:#{$font-weight};
    text-rendering:optimizeLegibility;
  }
}
@mixin media($width,$option:0) {
  @if $option == 0{
    @media (max-width:$width) {
      @content;
    }
  } @else if $option == 1{
    @media (min-width:$width + 1px) {
      @content;
    }
  }

}
@mixin placeholder{
  &::-webkit-input-placeholder{
    @content;
  }

  &:-moz-placeholder{ /* Firefox 18- */
    @content;
  }

  &::-moz-placeholder{ /* Firefox 19+ */
    @content;
  }

  &:-ms-input-placeholder{
    @content;
  }
}
@mixin func-two($body-font-size,$prefix:'') {
  @for $i from 0 through 10{
    $val:$margin-padding  / $body-font-size * $i + em;
    $val-m:-$margin-padding  / $body-font-size * $i + em;
    .g-p_#{$i}#{$prefix}{ padding:$val; }
    .g-ptb_#{$i}#{$prefix}{ padding-top:$val; padding-bottom:$val }
    .g-plr_#{$i}#{$prefix}{ padding-left:$val; padding-right:$val }

    .g-pt_#{$i}#{$prefix}{ padding-top:$val }
    .g-pr_#{$i}#{$prefix}{ padding-right:$val }
    .g-pb_#{$i}#{$prefix}{ padding-bottom:$val }
    .g-pl_#{$i}#{$prefix}{ padding-left:$val }

    .g-mt_#{$i}#{$prefix}{ margin-top:$val }
    .g-mr_#{$i}#{$prefix}{ margin-right:$val }
    .g-mb_#{$i}#{$prefix}{ margin-bottom:$val }
    .g-ml_#{$i}#{$prefix}{ margin-left:$val }
    .g-mtb_#{$i}#{$prefix}{ margin-top:$val; margin-bottom:$val }

    .g-mlr_#{$i}#{$prefix}{ margin-left:$val; margin-right:$val }

    .g-mt_#{$i}_m#{$prefix}{ margin-top:$val-m }
    .g-mr_#{$i}_m#{$prefix}{ margin-right:$val-m }
    .g-mb_#{$i}_m#{$prefix}{ margin-bottom:$val-m }
    .g-ml_#{$i}_m#{$prefix}{ margin-left:$val-m }

    .g-m_#{$i}_m#{$prefix}{ margin:$val-m; }
    .g-mtb_#{$i}_m#{$prefix}{ margin-top:$val-m; margin-bottom:$val-m }
    .g-mlr_#{$i}_m#{$prefix}{ margin-left:$val-m; margin-right:$val-m }

  }
}
@mixin margin-padding($margin-padding:8px,$body-font-size:16px) {
  @include func-two($body-font-size, '_xs');
  @media (min-width:$width_xs + 1px){ @include func-two($body-font-size, '_md'); }
  @media (min-width:$width_md + 1px){ @include func-two($body-font-size, '_lg'); }
  @media (min-width:$width_lg + 1px){ @include func-two($body-font-size); }



}
@mixin box-decor($box-shadow:1,$border:1,$background:1) {
  border-radius:$br_1;
  transition:$trans;
  border:1px solid transparent;
  @if $border == 1{ border-color:$clr_blue; } @else{ border-color:transparent; }
  @if $background == 1{ background-color:$clr_wh; } @else{ background-color:transparent; }
  @if $box-shadow == 1{ box-shadow:$bxsh_3 } @else{ box-shadow:none }
}




