/*! icon */
.icon{
  transition:$trans;
  display:inline-block;

  &-vk{ background:no-repeat center svg-load('icon_vk.svg', height=18) }
  &-fa{ background:no-repeat center svg-load('icon_fa.svg', height=25) }
  &-inst{ background:no-repeat center svg-load('icon_inst.svg', height=20) }
  &-youtube{ background:no-repeat center svg-load('icon_youtube.svg', height=25) }
  &-write{ background:no-repeat center svg-load('icon_write.svg', height=25) }
  &-ak-soc{ background:no-repeat center svg-load('icon_ak.svg', height=15) }

  &-ak:before{ content:svg-load('icon_ak.svg', width=20, height=15) }
  &-phone:before{ content:svg-load('icon_phone.svg', width=15, height=20) }
  &-picture:before{ content:svg-load('icon_picture.svg', width=18, height=19) }

  &-play{
    background:$clr_3 no-repeat center svg-load('icon_play.svg');
    border-radius:50%;
    width:4.063em;
    height:4.063em;

    &:before{
      @extend %oreol;
    }
  }

}
.icon-soc-big{
  &:not(&_ak):hover:before, &_inst:before{
    background-color:$clr_3;
  }
  &_inst:hover:before{
    background-image:linear-gradient(22deg, $clr_3 0%, $clr_3 50%, $clr_3 100%);
    background-position:0 0;

  }
  &:before{
    width:4.613em;
    height:4.613em;
    border-radius:50%;
    content:'';
    display:block;
    /*  transition:$trans;*/
  }
  &_vk:before{ background:#3a6f9b no-repeat center svg-load('icon_vk.svg', fill="white", width="30", height=30) }
  &_fa:before{ background:#345f9e no-repeat center svg-load('icon_fa.svg', fill="white", width="15", height=30) }
  &_inst:before{ content:svg-load('icon_inst.svg', width="35", height=30, fill="white"); background:no-repeat center; line-height:6.5em; background-image:linear-gradient(22deg, #ffd978 0%, #d52e76 50%, #533ce4 100%) }
  &_youtube:before{ background:#e82a1b no-repeat center svg-load('icon_youtube.svg', fill="white", width="24", height=30) }
  &_write:before{ background:$clr_4 no-repeat center svg-load('icon_write.svg', fill="white", width="24", height=30) }
  &_ak:before{ background:$clr_4 no-repeat center svg-load('icon_head_2.svg', fill=$clr_5) }

}
/*! END icon */