.g-icon-down{
  display:block;
  $deg:45deg;
  $sz:40px;
  cursor:pointer;
  background:none;
  border:0;
  width:$sz;
  height:$sz;
  text-align:center;
  position:relative;
  line-height:$sz;
  color:$clr_2;
  padding:0;
  &:before, &:after{ width:6px; height:2px; background:currentColor; content:''; display:inline-block; margin:-1px; position:relative; top:-5px; transition:$trans }
  &:before{ transform:rotate($deg) }
  &:after{ transform:rotate(-($deg)) }

  &-open &:before, &_open:before, .g-icon-down-hover:not(.dropdown_disabled):hover &:before, .dropdown:not(.dropdown_disabled) .g-icon-down-focus:focus &:before{ transform:rotate(-($deg)) }
  &-open &:after, &_open:after, .g-icon-down-hover:not(.dropdown_disabled):hover &:after, .dropdown:not(.dropdown_disabled) .g-icon-down-focus:focus &:after{ transform:rotate($deg) }
}
.g-sr-only{
  position:absolute;
  width:1px;
  height:1px;
  padding:0;
  margin:-1px;
  overflow:hidden;
  clip:rect(0, 0, 0, 0);
  border:0;
}
.g-section-margin_big{
  margin:9em 0;
  @include media($width_xs){
    margin:4em 0;
  }
}
.g-section-margin{
  margin:6em 0;
  @include media($width_xs){
    margin:3em 0;
  }
}
.g-clr_4{
  color:$clr_4;
}
.g-fs_sm{
  font-size:0.875em;
}
.g-plain{ margin:0; padding:0; border:0; list-style-type:none; }
.g-fs_i_i{ font-size:inherit !important; }

.g-bbc_t{ border-bottom-color:transparent }
.g-bbc-h_t:hover{ border-bottom-color:transparent }
.g-bgi{ background:no-repeat center/cover }
.g-bgi_cn{ background-size:contain }

.g-cb:after{ display:block; visibility:hidden; clear:both; height:0; content:""; }
.g-o_h{ overflow:hidden }
.g-ws_nw{ white-space:nowrap }
.g-pr{ position:relative; }
.g-ps{ position:static; }
.g-cr_p{ cursor:pointer }
.g-trans{ transition:$trans }
.g-d_b{ display:block }
.g-ib{ display:inline-block; vertical-align:middle }
.g-hidden, .g-hidden-b:before{ display:none }
.touch-device .g-hide_md{ display:none }
.g-clr_inh{ color:inherit }
.g-lh_15{ line-height:1.5 }
.g-lh_12{ line-height:1.2 }
.g-lh_1{ line-height:1 }
.g-lh_1_i{ line-height:1.2 !important; }
.g-mb_0_i{ margin-bottom:0 !important; }
.g-row_ib_m .g-col, .g-va_m, .g-va_m.g-va_m{ vertical-align:middle }
.g-tabs__btn{ font-weight:500; color:#7382a0; font-size:1.286em; line-height:1.2; cursor:pointer; padding-bottom:14px; border-bottom:2px solid transparent; display:inline-block; vertical-align:middle; margin-right:5em; }
.g-tabs__btn:hover, .g-tabs__btn.active{ color:#002250; }
.g-tabs__btn.active{ border-color:#002250 }
@include margin-padding(10px, $font-size_main)

@media (max-width:$width_lg){
  html:not(.mobile) .g-grid, html:not(.mobile) .page{
    min-width:$width_lg;
  }

}
@media (max-width:1700px){
  .section-text_online-group .slick-arrow, .section-text_online-personal .slick-arrow, .section-text_evator .slick-arrow{
    display:none !important;
  }
}
/*
@media (max-width:$width_lg) {
  html, body{ min-width:990px; min-height:100%; height:100%; }
  .g-hide_lg{ display:none !important; }
  .g-show_lg{ display:block !important; }

  .g-grid{ max-width:100%; }
  .is-fixed-layout .g-grid{ width:960px; }

}
@media (max-width:$width_md) {
  html, body{ min-width:320px; }

  .g-hide_md, .g-hide-b_md:before{ display:none !important; }
  .g-show_md{ display:block !important; }
  .is-fixed-layout .g-grid{ width:735px; }
  .g-table_db_md, .g-table_db_md .g-table__cell{ display:block }

}
@media (max-width:$width_xs) {
  .g-grid, .g-col{ padding-right:10px; padding-left:10px }
  .g-row{ margin-left:-10px; margin-right:-10px; }
  .g-d_b_xs{ display:block }
  .g-hide_xs, .g-hide-b_xs:before{ display:none !important; }
  .g-show_xs{ display:block !important; }
  .g-show_ib_xs{ display:inline-block !important; }




}*/
