a[href^="tel:"]{ }
a, .link{
  color:inherit;
  transition:$trans;
  text-decoration:none;
  &:focus{outline:none}
}

a:not([class]){ border-bottom:1px solid; }
a{ }
a, .g-link, .g-link-js{
  transition:$trans;
}
.g-link-js{
  border-bottom:1px dashed;

  &-parent:hover &,
  &:hover{
    border-color:transparent;
  }
}