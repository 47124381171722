*, *:after, *:before{ box-sizing:inherit }
::-ms-clear, ::-ms-reveal{
  display:none;
}
hr{ display:block; height:1px; margin:1em 0; padding:0; border:0; background:#eaf1f8; }
html{ box-sizing:border-box; overflow-y:scroll;overflow-x:hidden;}
html, body{height:100%;margin:0;padding:0;min-width:320px;}
figure{ margin:0 }
img{ max-width:100% }
img:not([alt]){outline:2px solid red;}
table{ border-spacing:0; border-collapse:collapse; margin:2em 0; width:100%;vertical-align:middle }
tbody, tfoot, thead, tr, th, td{ vertical-align:inherit; }
svg, path{ transition:$trans }
%link-reset{
  color:inherit;
  text-decoration:none;
  border:inherit;
}
%icon-text{
  display:flex;
  align-items:center;
  &:before{ margin-right:1em }
}
%flex_middle{
  display:flex;
  align-items:center;
}
%bgi{
  background:no-repeat center/cover;
}
%bsh{
  box-shadow:0 0.75em 1em -.5em rgba(240, 31, 85, 0.39);
}
%bsh_2{
  box-shadow:0 0.5em 1em -.5em rgba(101, 97, 98, 0.45);
}
%abs{
  content:'';
  position:absolute;
  left:0;
  top:0; bottom:0;
  right:0;
}
%btn-hover{
  &:hover{
    transform:rotate(1deg) scale(.98);
    box-shadow:none;
  }
}
%op_0{
  opacity:0;
  visibility:hidden;
}
%op_1{
  opacity:1;
  visibility:visible;
}
%oreol{
  @extend %abs;
  border:1px solid $clr_3;
  margin:-.35em;
  border-radius:50%;
  animation:decor-circle-go 2s infinite ease-in-out;
}
%pos_img_content{
  position:absolute;
  top:50%;
  transform:translate(0, -50%);
}

@keyframes decor-circle-go{
  0%, 100%{ transform:scale(1) }
  50%{ transform:scale(.5) }
}
.awards{

  &__img{ transform:translate(0, 2em); margin-bottom:1em;}
  &__img_1:after{ content:url("../img/page_mobilapp/awards.jpg"); }
  &__img_2:after{ content:url("../img/page_mobilapp/awards_2.png"); }

  position:relative;
  margin-left:-8em;
  padding:3em 0 2em;

  @include media($width_xs){
    padding-bottom:0;
    &__img{
      background:no-repeat center/contain;
      padding-top:100%;
      transform:none;
      flex:1;
      &_1{background-image:url(../img/page_mobilapp/awards.jpg);}
      &_2{background-image:url(../img/page_mobilapp/awards_2.png);}
      &:after{display:none}
    }
  }
}




